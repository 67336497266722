<template>
  <div
    class="community-go-back"
    @click="reroute"
  > Start Trading </div>
</template>

<script>
import useTabs from '@/composables/useTabs'
import { onMounted, onUnmounted } from '@vue/composition-api'

export default {
  setup() {
    onMounted(() => {
      const frame = document.getElementById('circleIframe')
      frame.style.display = 'block'
    })
    onUnmounted(() => {
      document.getElementById('circleIframe').style.display = 'none'
    })
    const { setPage } = useTabs()

    return {
      setPage,
    }
  },
  methods: {
    reroute() {
      // eslint-disable-next-line no-underscore-dangle
      // if (/^\/\?code/.test(this.$router.history._startLocation) || this.$router.history._startLocation === '/community') {
      //   this.setPage({ path: '/', name: 'home' })
      //   return
      // }
      // this.$router.go(-1)
      this.$router.push({ path: '/', name: 'home' })
    },
  },
}
</script>

<style lang="scss">

</style>
